/* primary color- #FF6F6F */
.container {
  text-align: center;
  font-family: Poppins;
  width: 100vw;
  /* margin-top: em; */
}

.header {
  color: #7EC4F8;
  font-weight: bolder;
  font-size: 25px;
  /* margin: auto; */
  margin-left: -2.5em;
  /* margin-right: auto; */
  margin-top: -3em;
  position: absolute;
}

h2 {
  font-size: 50px;
  margin-top: 1em;
  font-family: Arial, Helvetica, sans-serif;
  /* background: linear-gradient(to right, #f1717b, #f9332c, #af7bb4, #4365f0, #6b85ef);  */
  background: linear-gradient(to right, #affc82, #70f92c,#f9d02c, #f4be7c); 
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* color: #f1717b; */
}

.pdf-button{
  background-color: #7EC4F8;
  width: 250px;
  color:#000;
  cursor: pointer;
  padding: 0.5em;
  border:#70f92c 1px solid;
  margin: auto;
  font-size: larger;
  font-weight: 600;
}

.upload-button {
  /* background-color: #D18AF7; */
  width: 250px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  color: #000;
  text-align: center;
  border-radius: 2px;
}
.upload-button-div{
  /* margin: auto; */
  border: #70f92c 2px solid;
  /* background-color: #7EC4F8; */
}
.button-container{
  display: flex;
  flex-direction: row;
  /* background-color: #6b85ef; */
  width: 650px;
justify-content: space-between;
  margin: auto;



}

.progress-bar{
  margin: auto;
}

.circular-bar{
  /* background-color: #4365f0; */
  margin-left: -1em;
position: absolute;
}

.converting-text{
position: absolute;
margin-top: 2em;
margin-left: -2.5em;
margin-bottom: 3em;
/* background-color: #4365f0; */
}

.upload-icon {
  margin-top: 10px;
  position: absolute;
  padding-left: 10px;
  color: #fff;
}

.features{
  display: flex;
  flex-direction: row;
  /* margin-top: -10px; */
  width:960px;
  margin: auto;
  margin-top: 2%;
}

.card-stylings-use{
  /* width: '300px', border:'none',borderBottomColor:'transparent',
   margin:'auto', marginTop:'1em',boxShadow: "none" */
   width:400px;
   border: none;
   margin: auto;
   margin-top: 1em;
   box-shadow: none;
}
.card-stylings{
  /* width: '300px', border:'none',borderBottomColor:'transparent',
   margin:'auto', marginTop:'1em',boxShadow: "none" */
   width:300px;
   border: none;
   margin: auto;
   margin-top: 1em;
   box-shadow: none;
}

.date-picker{
  margin-top: 100px;
  padding-top: 100px;
}


.footer{
margin-top: 0.25em;
font-size: 13px;
}

.error-message{
  /* marginTop:'-2em',position:'absolute',color:'red',textAlign:'center' */
  margin: auto;
  margin-top: -2em;
  margin-left: 5em;
  position: absolute;
  color: #f9332c;
  text-align: center;
}

.modal-container{
  width:45vw;
  height:93vh;
  background-color: #fff;
  border: 2px solid #7EC4F8;
  z-index: 2;
  color:#000;
  margin: auto;
  max-height: 100vh;
  
}
.modal-container-aes{
  width:85vw;
  height:88vh;
  background-color: #fff;
  border: 2px solid #7EC4F8;
  z-index: 2;
  color:#000;
  margin-left: -16em;
  /* margin: auto; */
  max-height: 100vh;
 
}
.textfield{
  margin-bottom: 5em;
}
.div-form{
  display: flex;
  flex-direction: column;
}

@media screen and (min-width:1366px) {
  .footer{
  margin-top: 2.5em;
  }
}
@media screen and (min-width:1440px) {
  .footer{
  margin-top: 4.5em;
  }
}
@media screen and (min-width:1535px) {
  .footer{
  margin-top: 5em;
  }
}
@media screen and (min-width:1680px) {
  .footer{
  margin-top: 10em;
  }
  .card-stylings{
  margin-top: 5em;
  }
  /* .features{
    margin-top:-35px;
  } */
}
@media screen and (min-width:2560px) {
  .footer{
  margin-top: 15em;
  }
  .card-stylings-use{
    margin-top: 10em;
  }
  .card-stylings{
  margin-top: 10em;
  }
}