.data-container{
    display: flex;
    flex-direction: row;

}

.left-component{
width:35vw;
margin-left: 1em;
/* background-color: aliceblue; */
min-height: 100vh;
}

.right-component{
margin-left: 1em;
width:61vw;
/* background-color: antiquewhite; */
min-height: 100vh;
}